// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  isMockEnabled: true, //TODO You have to switch this, TO USE THE REAL BACK-END
};

export const ADMIN_CONFIG = {
  //
  // apiEndpoint: 'https://ekiclik.admin.wissal-group.com/',
  // wsEndpoint: 'ws://ekiclik.admin.wissal-group.com/stomp',

  apiEndpoint: 'https://apiadmin-alsalam-stg.wissal-group.com/',
  wsEndpoint: 'ws://apiadmin-alsalam-stg.wissal-group.com/stomp',

  /*   apiEndpoint: 'http://localhost:8080/',
   wsEndpoint: 'ws://localhost:9090/stomp',*/


  title: 'Admin endpoint',
  clientId: 'wissalsys-ecommerce-admin',
  secret: 'XY7kmzoNzl100',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
